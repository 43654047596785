@import '../../variables';

.drop-zone-content{
    height:100%;
    border-radius: $radius;
    background-color: $color-background-content-upload !important;

    svg{
        color: $icon-upload;
    }

    .lst-errors{
        padding-left: 1rem;
    }
    .drop-zone-text{
        font-weight: bold;
        font-size: 1.5rem;
    }
}