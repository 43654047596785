@import '../variables';

.nav-menu-colas{
  background-color:black !important;
  z-index: 10;
}
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
  font-family: Roboto !important;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.initial-profile {
  width: 50px!important;
  height: 50px!important;
  background-color: $color-background-profile !important;
  z-index: 11;
}