$color-background-menu: black;
$color-background-profile: #6098f2;
$color-background-header-uploaded:#ecf8fd;
$color-background-content-upload:#ddebf2;
$color-background-form-control: #F0F4F6;

$radius: 0.6rem;
$color-border: #C8C8C8;

$color-title: #004568;

$color-warning: #F7C631;

$icon-upload: #b0ddee;

$div-upload-height: 57px;

$color-gray-text: #858889;
$color-title-button-close: #2B2B2B;
$color-button-footer: #1DB3DD;

$color-background-body: #F0F4F6;

$color-background-active-dropzone: #90EE90;