@import './variables';

body {
    min-width: 650px;
    padding-top: 70px;
    z-index: 9;
}
.Toastify__toast-container, .MuiSnackbar-root{
    padding-top: 4rem !important;
}

.MuiSnackbarContent-root {
    width: 100%;

    .MuiSnackbarContent-message {
        width: 100%;
    }
}

.body-home {

    .fiches {
        width: 80% !important;

        .no-filtered-fiches-text {
            font-size: 20px;
            color: $color-title;
            font-weight: bold;
        }
    }

    .upload-box {
        width: 15% !important;
    }
}

.success-toast {
    background-color: green !important;
    padding-top:100px;
}
.error-toast {
    background-color: red !important;
    //height: 70px;
    font-size: 16px !important;
    font-weight: bold !important;
}
.message-toast{
    margin-right: 1rem; 
}
.file-item{
    color: gray;
}
.file-name{
    font-size: 1rem;
}
.pricing-uploaded-box{
    border-radius: $radius;
    border: 2px solid $color-border;
    max-height: 100%;
    .pricing-content-uploaded{
        overflow: auto;
    }
}
.pricing-header-uploaded{
    background-color: $color-background-content-upload;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    font-weight: bold;
}

.table-fiches {
    margin-top: 20px;

    .table-head {
        background-color: orange;

        .column-head {
            font-weight: bold;
            font-size: 18px;
        }
    }
}

.file-date{
    color: gray;
    font-size: 0.8rem;
}