@import '../../variables';

.drawer-fiche-paper {
    width: 100%;
    height: 45%;
    max-width: 1500px !important;
    margin-top: 100px;
    overflow: auto;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

    .header-title {
        color: $color-title;
    }

    .important-field {
        color: $color-title;
        font-weight: bold;
        text-align: right;
        margin-right: 20px;
        font-size: 12px;
    }

    .mandatory-field {
        color: red;
        font-weight: bold;
        text-align: right;
        margin-right: 20px;
        font-size: 12px;
    }

    .precision-field {
        color: $color-title;
        text-align: right;
        font-size: 12px;
        margin-right: 20px;
    }

    .precision-field-2 {
        color: $color-gray-text;
        font-style: italic;
        text-align: left;
        margin-left: 20px;
    }

    #simple-dialog-title {
        .dialog-close-button {
            padding-top: 0px !important;
            padding-bottom: 0px !important;
        }

        .title-close {
            font-size: 12px;
            color: $color-title-button-close !important;
            padding-right: 8px;
        }
    }

    .dialog-header-border {
        background-color: $color-background-body;
        padding-left: 16px;
        border-bottom: 1px solid $color-title-button-close;
    }

    .fiche-form-content {
        overflow: auto;
        min-height: 75px;

        .title-control {
            color: $color-gray-text !important;
            font-size: 12px;
            padding-right: 8px;
        }

        .button-index-change {
            color: $color-button-footer;
            font-size: 14px;
        }

        .form-control {
            width: 100%;


            input, .MuiSelect-select {
                background-color: $color-background-form-control;
            }

            .MuiSelect-selectMenu, .MuiInput-input {
                padding-left: 0.7em;
            }

            .reader-input input {
                background-color: white !important;
            }
        }
    }

    .fiche-form-footer {
        width: 100%;
        margin-left: auto;

        .warning-field {
            color: $color-warning;
            margin-right: 20px;
            font-size: 12px;
        }

        .button-index-change {
            color: $color-button-footer;
            font-size: 14px;
        }

        .Mui-disabled {
            opacity: 0.2;
        }

        .count-files {
            color: $color-title;
            font-size: 20px;
        }

        button.btn-actions {
            border: 1px solid;
        }
    }
}
